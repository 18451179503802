export type localStorageDataTypes = FractionsSoloData;

export type FractionsSoloData = {
  completedPlaylists: string[];
  activity: string;
};

export const fractionsLocalStorageKey = 'fractions_solo_key';

export const saveToLocalStorage = (key: string, state: localStorageDataTypes) => {
  localStorage.setItem(key, JSON.stringify(state));
};

export const retrieveFromLocalStorage = (key: string): localStorageDataTypes | null => {
  const storageData = localStorage.getItem(key);
  return storageData ? JSON.parse(storageData) : null;
};

export const tryStorage = (activity: string): localStorageDataTypes => {
  const data: localStorageDataTypes = retrieveFromLocalStorage(activity) || {
    activity: activity,
    completedPlaylists: [],
  };
  return data;
};

export const saveStorage = (activity: string, data: localStorageDataTypes) => {
  saveToLocalStorage(activity, data);
};

export const playlistCompleted = (playlist: string) => {
  const localSession: localStorageDataTypes = tryStorage(fractionsLocalStorageKey);
  if (!localSession.completedPlaylists.includes(playlist)) {
    localSession.completedPlaylists.push(playlist);
    saveStorage(fractionsLocalStorageKey, localSession);
  }
};

export const completedPlaylists = (): string[] => {
  const data: localStorageDataTypes | null = retrieveFromLocalStorage(fractionsLocalStorageKey);
  return data ? data.completedPlaylists : [];
};
