import '../duck-fraction-activity.scss';
import '../../core/activity/activity-component.scss';

import { DuckFractionActivity, DuckFractionActivityReport } from '../duck-fraction-activity';
import { LevelCounterView } from './level-counter-view';
import { SoloModeTransitionViewController } from './solo-mode-transition-view-controller';
import { loadAssetBundles } from '@puzzles/core/asset-utility';
import { ActionMenuButtonView } from '../action-menu-button-view';
import { CharacterView } from '../character-view';
import { PortalView } from '../entityviews/portal-view';
import { PuzzleData } from '../puzzle-data';
import { permutations } from '@puzzles/core/math/utility';
import { createState } from '../puzzle-state';
import { wait, waitUntil } from '@puzzles/core/async/awaitable';
import FontFaceObserver from 'fontfaceobserver';
import { SoloModeFinalScoreView } from './solo-mode-final-score-view';
import { ActionViewController } from '../action-view-controller';
import { CharacterFeedbackController } from '../character-feedback-controller';
import { PuzzleActivityViewController } from '../puzzle-activity-view-controller';
import { Sound, sound } from '@pixi/sound';
import { BatteryView } from '../entityviews/battery-view';
import { TutorialPointerView } from 'src/tutorialHand/TutorialPointerView';
import { SoloModeResultCoinView } from './solo-mode-result-coin-view';

import bgm from '../assets/sound/bgm.mp3';
import { VehicleProgressView } from '../entityviews/vehicle-progress-view';

// Add new functioinality here. You could either extend the base class like below or use class composition, whichever is easiest for you to work with. Just make sure any solo-lite change
// don't end up in DuckFractionActivity directly as we won't want to port solo-lite.
export class DuckFractionActivitySolo extends DuckFractionActivity {
  private levelCounter!: LevelCounterView;
  private transitionViewController!: SoloModeTransitionViewController;
  private score: number = 0;
  private rejectPromise: ((reason?: any) => void) | undefined;

  constructor(container: HTMLElement, playTutorial: boolean = false, maxLevelCount: number = 2, abort?: AbortSignal) {
    super(container, playTutorial);

    sound.volumeAll = 0.6;
    this.levelCounter = new LevelCounterView(maxLevelCount);

    if (abort) {
      abort.onabort = this.rejectActivity.bind(this);
    }
  }

  override async initialize(progression: (progress: number) => void = () => {}) {
    if (this.disposed) return;

    await new FontFaceObserver('Montserrat').load();
    await loadAssetBundles(
      [
        CharacterView,
        BatteryView,
        PortalView,
        VehicleProgressView,
        ActionMenuButtonView,
        ActionViewController,
        CharacterFeedbackController,
        PuzzleActivityViewController,
        TutorialPointerView,
        SoloModeTransitionViewController,
        SoloModeResultCoinView,
        SoloModeFinalScoreView,
      ],
      progression
    );

    this.levelCounter.zIndex = 9999;
    this.application.stage.addChild(this.levelCounter);
    this.transitionViewController = new SoloModeTransitionViewController();
    this.transitionViewController.zIndex = Number.MAX_SAFE_INTEGER;
    this.application.stage.addChild(this.transitionViewController);

    Sound.from({ url: bgm, loop: true, volume: 0.3, autoPlay: true });
  }

  override async play(configuration: PuzzleData): Promise<DuckFractionActivityReport | undefined> {
    if (this.levelCounter.isComplete) {
      return undefined;
    } else {
      this.levelCounter.addCount();
    }

    return new Promise((resolve, reject) => {
      this.rejectPromise = reject;

      (async () => {
        this.transitionViewController.hide();

        const skin = this.skinController.getSkin();
        await this.loadPuzzleSkin(skin);

        const state = createState(configuration);
        const time = Date.now();

        this.refreshController(state, skin);

        if (Object.hasOwn(configuration, 'tutorial')) {
          this.playTutorial = (configuration as any).tutorial;
        }
        if (this.playTutorial) this.startTutorial(state);

        this.application.stage.sortChildren();
        this.onResize();

        let resultReport: DuckFractionActivityReport;
        let puzzleCompleted = false;
        let tries = 0;
        let addScore = 0;

        this.controller.onComplete = (triesNumber) => {
          this.skinController.addLevel();
          this.isFirstPuzzle = false;
          this.playTutorial = false;

          tries = triesNumber;
          addScore = this.getScore(triesNumber);

          const sourceSlotsCount = this.controller.view.actionView.sourceSlots.length;
          const targetSlotsCount = this.controller.view.actionView.targetSlots.length;
          const permutationsCount = permutations(sourceSlotsCount, targetSlotsCount);
          const triesCombinationRatio = permutationsCount > 1 ? (triesNumber - 1) / (permutationsCount - 1) : 0;

          resultReport = {
            elapsedTime: Date.now() - time,
            score: addScore,
            tries: triesNumber,
            success: true,
            triesCombinationRatio: triesCombinationRatio,
            config: configuration,
          };

          puzzleCompleted = true;
          return true;
        };

        await waitUntil(() => puzzleCompleted);

        await this.displayResult(tries, this.score, addScore);
        this.score += addScore;

        resolve(resultReport!);
      })();
    });
  }

  public rejectActivity() {
    if (this.rejectPromise === undefined) return;
    this.rejectPromise('exit');
    this.rejectPromise = undefined;
  }

  private getScore(tries: number) {
    if (tries > 2) return 100;
    if (tries > 1) return 200;
    return 300;
  }

  private async displayResult(tries: number, oldScore: number, addScore: number) {
    await this.transitionViewController.showResult(tries, oldScore, addScore);
    await wait(1000);

    const nextLevel = this.levelCounter.NowCount + 1;
    if (nextLevel > this.levelCounter.MaxCount) return;
    await this.transitionViewController.showTransition(nextLevel, this.levelCounter.MaxCount);
    await wait(1000);
  }

  onResize(): void {
    super.onResize();

    let screen = this.application.screen;
    this.levelCounter.resize(screen.width, screen.height);
    this.transitionViewController?.resize(screen.width, screen.height);
  }
}
