// TODO: this whole session system needs re-working to be reusable for every activity and composable with different flows

import { ActivitySequenceReport, createInitialReport } from '@puzzles/core/activity/activity-player';
import { wait, waitUntil } from '@puzzles/core/async/awaitable';
import { PixiTransitioner } from '@puzzles/core/transition/pixi-transitioner';
import { ProgressBarTransitioner } from '@puzzles/core/transition/progress-bar-transitioner';
import { DuckFractionActivity } from './duck-fraction-activity';
import { DuckFractionActivitySolo } from './solo/duck-fraction-activity-solo';
import { DuckTransitionView } from './duck-transition-view';
import { PuzzleData } from './puzzle-data';
import { parseLevelResource, sessionBundle } from './puzzle-data-bundle';
import { PuzzleGenerationParametrizerProps, PuzzleGenerationProps, generateSafely } from './puzzle-data-generator';
import { humanizeDuration } from './utilities';
import { PlaySessionData, RuleData } from './puzzle-session-data';
import { puzzleActivityEvents as puzzleEventBus } from 'src/controller/features/activity/events/puzzle-activity-events';
import { EndFractionLevelEventData } from '@puzzles/common/typedefs/puzzles/types';
import { BusEventType } from '@puzzles/core/event-bus/constants';
import { ActivityId } from '@puzzles/core/types';
import { SoloModeFinalScoreView } from './solo/solo-mode-final-score-view';
import {
  trackEvent,
  DuckFractionsSoloStartSession,
  DuckFractionsSoloEndSession,
  DuckFractionsSoloEndLevel,
} from '@puzzles/core/amplitude';
import { DuckFractionActivityReport } from './duck-fraction-activity';
import { playlistCompleted } from './puzzle-local-storage';

export async function initializeLevel(
  stage: HTMLElement,
  puzzleData: PuzzleData,
  tutorial: boolean,
  solo: boolean = false
) {
  const activity = solo ? new DuckFractionActivitySolo(stage, tutorial, 10) : new DuckFractionActivity(stage, tutorial);
  await new ProgressBarTransitioner(activity.application).transition(async () => {
    await activity.initialize();
  });

  activity.play(puzzleData);
}

// TODO: for now we forked the ActivitySequencePlayer to make it work with the new PlaySessionData
// TODO: the idea would be that we can easily compose and reuse running/playing/flow logic and adapt it to different scenarios
// TODO: for now, I haven't found the best way to do that, but it definitely will be an async generator or something similar
// TODO: Allow different activity types. I've just added a union to allow for solo lite for now, as I understand this file is WIP
export async function executePlaySessionWithActivity(
  activity: DuckFractionActivity | DuckFractionActivitySolo,
  playSession: PlaySessionData
) {
  await new ProgressBarTransitioner(activity.application)
    .resize(activity.application.screen.width, activity.application.screen.height)
    .transition(async (progression) => {
      await activity.initialize(progression);
    });

  console.log('start session ' + playSession.id);

  const report: ActivitySequenceReport<PuzzleData> = createInitialReport();
  let sessionLevelIndex = 0;
  let sectionIndex = 0;
  let levelIndex = 0;
  let lastLevelId = '';
  let exitSession = false;
  const isSolo = activity instanceof DuckFractionActivitySolo;

  if (isSolo) {
    trackEvent({
      eventName: DuckFractionsSoloStartSession,
      activity: ActivityId.DuckFractionsSolo,
      play_session_id: playSession.id,
    });
  }

  for (const section of playSession.sections) {
    console.log('start section ' + sectionIndex, section);

    const rules = section.rules || playSession.rules;
    const generator = generatePlaylist(section.levels);
    const transitioner = new PixiTransitioner(activity.application, new DuckTransitionView());
    let current: PuzzleData | undefined;
    let sectionLevelIndex = 0;

    for await (const level of generator) {
      current = level;

      if (sessionLevelIndex == 0) {
        Object.defineProperties(level, {
          tutorial: {
            value: true,
            writable: false,
          },
        });
      }

      console.log('start section ' + sectionIndex + ' level ' + sectionLevelIndex, current);

      const playlistId = playSession.id;
      const singleReport = await activity.play(current).catch((message) => {
        if (message === 'exit') exitSession = true;
      });

      if (exitSession) break;
      if (singleReport === undefined) break;

      lastLevelId = section.levels[0] ?? 'UNKNOWN';

      puzzleEventBus.publish<EndFractionLevelEventData>(BusEventType.EndLevel, {
        activity: ActivityId.DuckFractions,
        levelId: lastLevelId,
        levelIndex: levelIndex,
        playSessionId: playlistId,
        tries: singleReport.tries,
        triesCombinationRatio: singleReport.triesCombinationRatio,
        report: singleReport,
      });

      report.reports.push(singleReport);
      report.elapsedTime += singleReport.elapsedTime;
      report.score += singleReport.score;
      report.success = report.success || singleReport.success;
      report.activitiesCompleted++;

      if (isSolo) {
        trackEvent({
          eventName: DuckFractionsSoloEndLevel,
          activity: ActivityId.DuckFractionsSolo,
          play_session_id: playSession.id,
          level_id: lastLevelId,
          time: 0,
          tries: singleReport.tries,
          tries_combinations_ratio: singleReport.triesCombinationRatio,
        });
      }

      console.log('end section ' + sectionIndex + ' level ' + sectionLevelIndex, singleReport);

      let skip = false;
      for (const rule of rules) {
        if (rule.type == 'skip' && sectionLevelIndex + 1 >= rule.chain) {
          let accomplished = true;
          for (let i = report.reports.length - 1; i >= report.reports.length - rule.chain; i--) {
            if (report.reports[i].tries >= rule.tries) {
              accomplished = false;
              break;
            }
          }
          if (accomplished) {
            skip = true;
          }
        }
      }

      current = undefined;
      //TODO: All transitions should be done here
      if (!isSolo) await transitioner.transition(() => waitUntil(() => true)); // TODO: this is a hack to make the transitioner work, we need to find a better way to do this
      sessionLevelIndex++;
      sectionLevelIndex++;
      levelIndex++;
      if (skip) {
        console.log('skip section ' + sectionIndex);
        sectionIndex++;
        break;
      }
    }
    if (exitSession) break;
    sectionIndex++;
  }

  if (isSolo) {
    trackEvent({
      eventName: DuckFractionsSoloEndSession,
      activity: ActivityId.DuckFractionsSolo,
      play_session_id: playSession.id,
      end_source: exitSession ? 'Exit Button' : 'Complete',
      number_levels_completed: levelIndex,
      last_done_level_id: lastLevelId,
      number_gems: report.score,
      average_tries: report.reports.reduce((acc, report) => acc + report.tries, 0) / report.reports.length,
      average_tries_combinations_ratio:
        report.reports.reduce((acc, report) => acc + (report as DuckFractionActivityReport).triesCombinationRatio, 0) /
        report.reports.length,
    });
    // Save playlists completed in localstorage
    playlistCompleted(playSession.id);
  }

  console.log('end session', report);
  if (!exitSession) {
    new PixiTransitioner(
      activity.application,
      isSolo
        ? new SoloModeFinalScoreView(report.score)
        : new DuckTransitionView('All Complete in ' + humanizeDuration(report.elapsedTime) + ' seconds', 'happy')
    ).transition(async () => {
      await wait(40000000);
      activity.dispose();
    });
  }

  if (isSolo && !exitSession) {
    // Redirect to solo Fractions page
    setTimeout(() => {
      window.location.href = '/start/fractions/solo';
    }, 6000);
  }

  return report;
}

export async function executePlaySession<T>(stage: HTMLElement, session: string, tutorial: boolean) {
  const activity = new DuckFractionActivity(stage, tutorial);
  const rules: RuleData[] = [{ type: 'skip', chain: 2, tries: 3 }];
  executePlaySessionWithActivity(activity, parsePlaySessionData(session, 'auto', rules));
}

export async function executePlaySessionSolo<T>(
  stage: HTMLElement,
  session: string,
  tutorial: boolean,
  maxLevelCount: number,
  abort?: AbortSignal
) {
  const activity = new DuckFractionActivitySolo(stage, tutorial, maxLevelCount, abort);
  const rules: RuleData[] = [{ type: 'skip', chain: 2, tries: 3 }];
  executePlaySessionWithActivity(activity, parsePlaySessionData(session, 'auto', rules));
}

function parsePlaySessionData(session: string, id: string = 'auto', rules: RuleData[] = []): PlaySessionData {
  console.log('parsePlaySessionData', session);
  if (sessionBundle.has(session)) return sessionBundle.get(session)!;
  const sectionSplit = session.split('|');
  const playSessionData: PlaySessionData = {
    id: id,
    rules: rules,
    sections: sectionSplit.map((s) => {
      return { levels: s.split(' ') };
    }),
  };
  return playSessionData;
}

export function* generatePlaylist(levels: string[]) {
  for (var level of levels) {
    yield* generateLevels(parseLevelResource(level));
  }
}

function* generateLevels(level: PuzzleData[] | PuzzleGenerationProps | PuzzleGenerationParametrizerProps) {
  if (Array.isArray(level)) {
    for (var l of level) {
      yield l;
    }
  } else {
    yield* generateProceduralLevel(level);
  }
}

function* generateProceduralLevel(
  level: PuzzleGenerationProps | PuzzleGenerationParametrizerProps,
  count: number = Number.MAX_VALUE
) {
  for (var i = 0; i < count; i++) {
    yield generateSafely(level);
  }
}
