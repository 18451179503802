export interface IActivityPlayer<TConfig, TReport extends ActivityReport<TConfig> = ActivityReport<TConfig>> {
  play(configuration: TConfig): Promise<TReport | undefined>;
}

export type ActivityReport<TConfig> = {
  config: TConfig;
  elapsedTime: number;
  score: number;
  tries: number;
  success: boolean;
};

export type ActivitySequenceReport<TConfig> = ActivityReport<TConfig> & {
  reports: ActivityReport<TConfig>[];
  activitiesCompleted: number;
};

export function createInitialReport<TConfig>(): ActivitySequenceReport<TConfig> {
  return {
    config: undefined as unknown as TConfig,
    elapsedTime: 0,
    score: 0,
    tries: 0,
    success: false,
    reports: [],
    activitiesCompleted: 0,
  };
}
