import { Assets, Container, Sprite, Texture } from "pixi.js";
import gsap from "gsap";
import { assets } from "@puzzles/core/asset-utility";
import { Emitter } from "@pixi/particle-emitter";

import coinSprite from '../assets/solo/coin.svg?url';
import fillEmitter from '../assets/solo/emitter-fill-coin.json?url'

const originCoinSize = 100;

@assets("SoloModeResultCoinView", {
   coinSprite : coinSprite, 
    fillEmitter: fillEmitter,
})
export class SoloModeResultCoinView extends Container{
    private emptyCase: Sprite;
    private filledCaseSprite: Sprite;
    private filledCase: Container = new Container();
    private emitterContainer = new Container();
    private emitterFill: Emitter;

    public set Size(size: number){
        this.filledCaseSprite.width = this.filledCaseSprite.height = size;
        this.emptyCase.width = this.emptyCase.height = size * 1.15;
        this.emitterContainer.scale.set(size / originCoinSize);
    }

    public get Filled() { return this.filledCaseSprite.x > 0;}

    constructor(){
        super();

        this.emptyCase = new Sprite(Texture.from(coinSprite));
        this.emptyCase.tint = 0x000000;
        this.emptyCase.alpha = 0.2;
        this.emptyCase.anchor.set(0.5);
        this.addChild(this.emptyCase);
        
        this.filledCaseSprite = new Sprite(this.emptyCase.texture);
        this.filledCaseSprite.anchor.set(0.5);
        this.filledCase.addChild(this.filledCaseSprite);
        this.addChild(this.filledCase);

        this.addChild(this.emitterContainer);
        this.emitterFill = new Emitter(this.emitterContainer, Assets.get('fillEmitter'));
        this.emitterContainer.position.set(0);

        this.reset();
    }

    public fill(){
        gsap.to(this.filledCase.scale, {x: 1, duration: 0.25, ease: "back.out(1.7)"});
        this.emitterFill.emit = true;
    }

    public reset(){
        this.filledCase.scale.x = 0;
    }
}