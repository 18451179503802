import { Resizable } from "@puzzles/core/interfaces/resizable";
import { Assets, Container, Text, TextStyle } from "pixi.js";
import { SoloModeResultCoinView } from "./solo-mode-result-coin-view";
import { GradientSprite } from "@puzzles/core/pixi/gradient-sprite";
import { wait } from "@puzzles/core/async/awaitable";
import { gsap } from "gsap";
import { playBump } from "@puzzles/core/animation-utility";
import { assets } from "@puzzles/core/asset-utility";
import { Emitter } from "@pixi/particle-emitter";
import { sound } from "@pixi/sound";

import sparkEmitter from '../assets/solo/emitter-result-spark.json?url';
import textEmitter from '../assets/solo/emitter-result-text-change.json?url';
import perfectEmitter from '../assets/solo/emitter-result-prefect.json?url';
import coinFillSound0 from '../assets/sound/coin_one_001.mp3?url'
import coinFillSound1 from '../assets/sound/coins_two_001.mp3?url'
import coinFillSound2 from '../assets/sound/coins_three_001.mp3?url'
import resultPerfectSound from '../assets/sound/result_perfect_001.mp3?url'
import levelChangeSound from '../assets/sound/stage_number_change_001.mp3?url'

export type SoloModeTransitionViewProps = {
    textStyle: TextStyle;
    borderRadius: number;
    coinCaseSize: number;
    coinCaseMarginX: number;
    eachCoinPadding: number;
};

export const defaultSoloModeTransitionViewProps: SoloModeTransitionViewProps = {
    textStyle: new TextStyle({
        fontFamily: 'Montserrat',
        fontWeight: '800',
        fontSize: 100,
        fill: 0xFFFFFF,
        dropShadow: true,
        dropShadowAngle: 0.9,
        dropShadowBlur: 4,
        dropShadowDistance: 3,
    }),
    coinCaseSize: 100,
    coinCaseMarginX: 0.2,
    eachCoinPadding: 0.1,
    borderRadius: 15,
};

@assets('SoloModeTransitionViewController', {
    sparkEmitter: sparkEmitter,
    textEmitter: textEmitter,
    perfectEmitter: perfectEmitter,
    coinFillSound0: coinFillSound0,
    coinFillSound1: coinFillSound1,
    coinFillSound2: coinFillSound2,
    resultPerfectSound: resultPerfectSound,
    levelChangeSound: levelChangeSound,
})
export class SoloModeTransitionViewController extends Container implements Resizable {
    private gradients: GradientSprite[] = [];

    private props: SoloModeTransitionViewProps;
    private defaultProps: SoloModeTransitionViewProps;

    private resultView: SoloModeResultView;
    private transitionView: SoloModeTransitionView;

    constructor(
        props: Partial<SoloModeTransitionViewProps> = {},
    ) {
        super();

        this.props = { ...defaultSoloModeTransitionViewProps, ...props };
        this.defaultProps = { ...this.props };

        const gradientSteps = [ 
            [
                { percent: 0, value: '#4C2E9A' },
                { percent: 0.3, value: '#7C43AC' },
                { percent: 0.5, value: '#3D79ED' },
                { percent: 0.7, value: '#3D79ED' },
                { percent: 1, value: '#58339E' },
            ],
            [
                { percent: 0, value: '#3D687B' },
                { percent: 0.3, value: '#509740' },
                { percent: 1, value: '#67D185' },
            ],
            [
                { percent: 0, value: '#94294A' },
                { percent: 0.6, value: '#FFD439' },
                { percent: 0.8, value: '#FFD439' },
                { percent: 1, value: '#D9822A' },
            ]
        ];

        for(let i = 0; i < gradientSteps.length; i++){
            const gradient = new GradientSprite({
                steps: gradientSteps[i],
            });
            gradient.angle = 90;
            gradient.anchor.set(0.5);
            gradient.visible = false;
            this.gradients.push(gradient);
            this.addChild(gradient);
        }

        this.resultView = new SoloModeResultView(this.props.textStyle);
        this.addChild(this.resultView);

        this.transitionView = new SoloModeTransitionView(this.props.textStyle);
        this.addChild(this.transitionView);

        this.visible = false;
    }

    public async showResult(tries: number, nowScore: number, addScore: number) {
        this.transitionView.hide();
        this.gradients[0].visible = tries > 2;
        this.gradients[1].visible = tries === 2;
        this.gradients[2].visible = tries <= 1;

        await this.showBackground();
        await this.resultView.show(tries, nowScore, addScore);
    }

    public async showTransition(nextLevel: number, maxLevel: number) {
        this.resultView.hide();
        await this.showBackground();
        await this.transitionView.show(nextLevel, maxLevel);
    }

    private async showBackground() {
        if (this.visible) return;

        this.alpha = 0;
        this.visible = true;
        await gsap.to(this, { alpha: 1, duration: 0.2 });
    }

    public async hide() {
        await gsap.to(this, { alpha: 0, duration: 0.3 });
        this.resultView.visible = this.transitionView.visible = this.visible = false;
    }

    resize(width: number, height: number): void {
        const center = { x: width / 2, y: height / 2 };

        this.gradients.forEach((gradient) => {
            gradient.width = height;
            gradient.height = width;
            gradient.position.set(center.x, center.y);
        });
       
        this.props = { ...this.defaultProps };
        const fontSize = Math.min(this.props.textStyle.fontSize as number, width / 6);
        this.props.textStyle.fontSize = fontSize;

        this.resultView.resize(width, height, this.props);
        this.transitionView.resize(width, height, this.props);
    }
}

export class SoloModeResultView extends Container implements Resizable {
    private scoreText: Text;
    // private evaluateContainer = new Container();
    // private evaluateGraphics = new Graphics();
    // private evaluateText: Text;
    private emitterSpark: Emitter;
    private coinCases: SoloModeResultCoinView[] = [];
    private coinContainer = new Container();
    private emitterPrefect: Emitter;

    constructor(textStyle: TextStyle) {
        super();

        const prefectContainer = new Container();
        this.addChild(prefectContainer);
        this.emitterPrefect = new Emitter(prefectContainer, Assets.get('perfectEmitter'));

        this.scoreText = new Text("1000", textStyle);
        this.scoreText.anchor.set(0.5);
        this.addChild(this.scoreText);

        // this.addChild(this.evaluateContainer);
        // this.evaluateContainer.angle = -5;
        // this.evaluateContainer.addChild(this.evaluateGraphics);
        // this.evaluateText = new Text("GOOD", textStyle);
        // this.evaluateText.anchor.set(0.5);
        // this.evaluateContainer.addChild(this.evaluateText);
        this.emitterSpark = new Emitter(this, Assets.get('sparkEmitter'));

        this.addChild(this.coinContainer);
        for (let i = 0; i < 3; i++) {
            const coinCase = new SoloModeResultCoinView();
            this.coinContainer.addChild(coinCase);
            this.coinCases.push(coinCase);
        }

        this.visible = false;
    }

    public async show(tries: number, nowScore: number, addScore: number) {
        let gainCoinCount = 0;
        if (tries <= 1) {
            // this.evaluateText.text = "PERFECT";
            gainCoinCount = 3;
            
            this.emitterPrefect.emit = this.emitterSpark.emit = true;
            sound.play('resultPerfectSound', { volume: 0.5 });
            await wait(100);
        }
        else if (tries <= 2) {
            // this.evaluateText.text = "GOOD";
            gainCoinCount = 2;
        }
        else {
            // this.evaluateText.text = "OK";
            gainCoinCount = 1;
        }
        this.scoreText.text = nowScore.toString();
        this.coinCases.forEach(c => c.reset());

        // this.evaluateContainer.scale.set(0.7);
        // this.evaluateContainer.alpha = 0;

        this.visible = true;
        gsap.fromTo(this, { alpha: 0 }, { alpha: 1, duration: 0.3 });
        await gsap.fromTo(this, { y: 50 }, { y: 0, duration: 0.4, ease: 'back.out(3)' });

        // gsap.to(this.evaluateContainer, { alpha: 1, duration: 0.2 });
        // gsap.to(this.evaluateContainer.scale, { x: 1, y: 1, duration: 0.3, ease: 'back.out(3)' });
        // if(tries < 3){
        //     this.emitterSpark.playOnce();
        // }
        await wait(500);

        const targetScore = nowScore + addScore;
        const eachAddScore = Math.floor(addScore / gainCoinCount);
        for (let i = 0; i < gainCoinCount; i++) {
            this.coinCases[i].fill();
            sound.play('coinFillSound' + i, { volume: 0.8 });
            if (i < gainCoinCount - 1) nowScore += eachAddScore;
            else nowScore = targetScore;
            this.scoreText.text = nowScore.toString();
            playBump(this.scoreText, 0.1, 1, 1.2, 'power1.out', true);
            await wait(400);
        }
    }

    public async hide() {
        if (!this.visible) return;
        await gsap.to(this, { alpha: 0, duration: 0.2 });
        gsap.to(this, { y: -50, duration: 0.2 });
        this.emitterPrefect.emit = this.emitterSpark.emit = false;
        this.visible = false;
    }

    resize(width: number, height: number, props: SoloModeTransitionViewProps = { ...defaultSoloModeTransitionViewProps }) {
        const center = { x: width / 2, y: height / 2 };

        // this.evaluateText.style = props.textStyle;
        // const textBounds = TextMetrics.measureText('D', props.textStyle);

        // this.evaluateGraphics.clear();
        // this.evaluateGraphics.lineStyle(fontSize / 10, 0xFFFFFF, 1);
        // this.evaluateGraphics.beginFill(0xFFFFFF, 0);
        // this.evaluateGraphics.drawRoundedRect( 
        //     -textBounds.width * 5 * 0.8, 
        //     -textBounds.height * 0.6, 
        //     textBounds.width * 5 * 1.6, 
        //     textBounds.height * 1.2, props.borderRadius
        // );
        // this.evaluateGraphics.endFill();

        // this.evaluateContainer.position.set(center.x, center.y + fontSize);

        this.emitterPrefect.updateOwnerPos(center.x, height);
        const drawCoinSpace = width - (width * (props.coinCaseMarginX * 2));
        const coinCount = this.coinCases.length;
        const coinSize = Math.min(
            props.coinCaseSize,
            (drawCoinSpace / coinCount) - (drawCoinSpace / coinCount * (props.eachCoinPadding * 2))
        );
        const coinPad = coinSize * (props.eachCoinPadding * 2);
        this.coinCases.forEach((c, i) => {
            const isCenter = i == 1;
            c.Size = coinSize + (isCenter ? coinSize * 0.2 : 0);
            c.position.set(
                - coinSize - coinPad + i * (coinSize + coinPad),
                - (isCenter ? coinSize * 0.3 : 0)
            );
        });
        this.coinContainer.position.set(center.x, center.y);
        
        this.emitterSpark.updateOwnerPos(center.x, center.y - coinSize);

        const fontSize = props.textStyle.fontSize as number;

        this.scoreText.style = props.textStyle.clone();
        this.scoreText.position.set(center.x, center.y - coinSize - fontSize);
    }
}

export class SoloModeTransitionView extends Container implements Resizable {
    private levelCountText: Text;
    private emitterText: Emitter;

    constructor(textStyle: TextStyle) {
        super();

        this.levelCountText = new Text("1 / 10", textStyle);
        this.levelCountText.anchor.set(0.5);
        this.addChild(this.levelCountText);

        this.emitterText = new Emitter(this, Assets.get('textEmitter'));
        this.visible = false;
    }

    public async show(nextLevel: number, maxLevel: number,) {
        this.levelCountText.text = `${nextLevel - 1} / ${maxLevel}`;

        this.visible = true;

        gsap.fromTo(this, { alpha: 0 }, { alpha: 1, duration: 0.3 });
        gsap.fromTo(this, { y: 50 }, { y: 0, duration: 0.4, ease: 'back.out(3)' });
        await wait(500);

        this.emitterText.emit = true;
        this.levelCountText.text = `${nextLevel} / ${maxLevel}`;
        sound.play("levelChangeSound");
    }

    public async hide() {
        if (!this.visible) return;
        gsap.to(this, { alpha: 0, duration: 0.2 });
        await gsap.to(this, { y: -50, duration: 0.2 });
        this.visible = false;
    }

    resize(width: number, height: number, props: SoloModeTransitionViewProps = { ...defaultSoloModeTransitionViewProps }): void {
        const center = { x: width / 2, y: height / 2 };

        this.levelCountText.style = props.textStyle.clone();
        this.levelCountText.position.set(center.x, center.y - (props.textStyle.fontSize as number) * 0.5);

        this.emitterText.updateOwnerPos(this.levelCountText.x, this.levelCountText.y);
    }
}