import { Resizable } from "@puzzles/core/interfaces/resizable";
import { Container, Text, TextStyle } from "pixi.js";

export class LevelCounterView extends Container implements Resizable{
    private text: Text;
    private textStyle: TextStyle;
    private defaultTextStyle: TextStyle;

    private maxCount: number;
    private nowCount: number = 0;
    public get NowCount(){ return this.nowCount; }
    public get MaxCount(){ return this.maxCount; }
    public get isComplete(){ return this.nowCount >= this.maxCount; }

    constructor(
        maxCount: number,  
        textStyle = new TextStyle({
            fontFamily: "Montserrat",
            dropShadow: true,
            dropShadowAngle: 0.7,
            dropShadowDistance: 3,
            fill: "#ffffff",
            fontSize: 40,
            fontWeight: '700',
        })
    ){
        super();

        this.textStyle = this.defaultTextStyle = textStyle;
        this.text = new Text("", textStyle);
        this.text.anchor.set(0);

        this.addChild(this.text);
        this.maxCount = maxCount;
    }
    
    public addCount(){
        this.nowCount++;
        this.text.text = this.nowCount + "/" + this.maxCount;
    }

    resize(width: number, height: number): void {
        this.textStyle = this.defaultTextStyle.clone();
        const fontSize = Math.min(this.defaultTextStyle.fontSize as number, width * 0.1);
        this.textStyle.fontSize = fontSize;

        this.text.style = this.textStyle;
        this.text.position.set(fontSize * 0.2, fontSize * 0.1);
    }
} 