import { assets } from "@puzzles/core/asset-utility";
import { GradientSprite } from "@puzzles/core/pixi/gradient-sprite";
import { Container, Graphics, Text, TextStyle, TextMetrics, Sprite, Texture, Assets } from "pixi.js";
import gsap from "gsap";
import { Rect } from "@puzzles/core/math/rectangle";
import { Emitter } from "@pixi/particle-emitter";
import { sound } from "@pixi/sound";

import treasureSprite from '../assets/solo/treasure.png?url';
import treasureLightEmitter from '../assets/solo/emitter-final-treasure-light.json?url';
import treasureBackLightEmitter from '../assets/solo/emitter-final-treasure-back-light.json?url';
import topLightEmitter from '../assets/solo/emitter-final-top-light.json?url';
import coinEmitter from '../assets/solo/emitter-final-coin.json?url';
import showTreasureSound from '../assets/sound/result_perfect_001.mp3?url';
import countScoreSound from '../assets/sound/fraction_square_replace_001.mp3?url';

export type SoloModeFinalScoreViewProps = {
  textStyles: TextStyle;
  borderRadius: number;
  borderColor: number;
  scoreColor: number;
};

export const defaultSoloModeFinalScoreViewProps: SoloModeFinalScoreViewProps = {
  textStyles: new TextStyle({
    fontFamily: 'Montserrat',
    fontWeight: '800',
    fontSize: 80,
    fill: 0xFFFFFF
  }),
  borderRadius: 20,
  borderColor: 0xFFFFFF,
  scoreColor: 0x48C25B,
};

@assets('finalScoreScreen', {
  treasureSprite: treasureSprite,
  treasureLightEmitter: treasureLightEmitter,
  treasureBackLightEmitter: treasureBackLightEmitter,
  topLightEmitter: topLightEmitter,
  coinEmitter: coinEmitter,
  showTreasureSound: showTreasureSound,
  countScoreSound: countScoreSound,
})
export class SoloModeFinalScoreView extends Container {
  container: Rect = { x: 0, y: 0, width: 100, height: 100 };
  animation?: gsap.core.Timeline;

  private props: SoloModeFinalScoreViewProps;
  private defaultProps: SoloModeFinalScoreViewProps;

  private gradient: GradientSprite;
  private treasureContainer: Container = new Container();
  private treasureSpriteContainer: Container = new Container();
  private scoreContainer = new Container();
  private graphics: Graphics = new Graphics();
  private yourScoreText: Text;
  private valueText: Text;

  private emitterTreasuerBackLight: Emitter;
  private emitterTreasuerLight: Emitter;
  private emitterTopLight: Emitter;
  private coinContainer: Container = new Container();
  private emitterCoin: Emitter;
  private emitterText: Emitter;

  private currentValue: number = 0;
  private targetValue: number;

  constructor(
    score: number,
    props: Partial<SoloModeFinalScoreView> = {},) {
    super();

    this.props = { ...defaultSoloModeFinalScoreViewProps, ...props };
    this.defaultProps = { ...this.props };
    this.targetValue = score;

    this.addChild(this.treasureContainer);

    this.gradient = new GradientSprite({
      steps: [
        { percent: 0, value: '#3D687B' },
        { percent: 0.3, value: '#509740' },
        { percent: 1, value: '#67D185' },
      ],
    });
    this.gradient.zIndex = -1;
    this.gradient.angle = 90;
    this.gradient.anchor.set(0.5);
    this.addChild(this.gradient);

    const treasureBackLightContainer = new Container();
    treasureBackLightContainer.zIndex = -1;
    this.treasureContainer.addChild(treasureBackLightContainer);
    this.emitterTreasuerBackLight = new Emitter(treasureBackLightContainer, Assets.get('treasureBackLightEmitter'));

    this.treasureContainer.addChild(this.treasureSpriteContainer);
    const shadow = new Graphics();
    shadow.beginFill(0x00_00_00, 0.2);
    shadow.drawEllipse(0, -110, 400, 90);
    shadow.endFill();
    this.treasureSpriteContainer.addChild(shadow);
    const treasure = new Sprite(Texture.from(treasureSprite));
    treasure.anchor.set(0.5, 1);
    this.treasureSpriteContainer.addChild(treasure);

    this.emitterTreasuerLight = new Emitter(this.treasureContainer, Assets.get('treasureLightEmitter'));
    this.treasureContainer.sortableChildren = true;
    this.treasureContainer.sortChildren();

    this.emitterTopLight = new Emitter(this, Assets.get('topLightEmitter'));

    this.addChild(this.scoreContainer);
    this.scoreContainer.zIndex = 9;
    this.yourScoreText = new Text('Score', this.props.textStyles);
    this.yourScoreText.anchor.set(0.5, 1);
    this.scoreContainer.addChild(this.yourScoreText);

    this.scoreContainer.addChild(this.graphics);

    this.valueText = new Text(0, this.props.textStyles);
    this.valueText.anchor.set(0.5);
    this.scoreContainer.addChild(this.valueText);
    this.scoreContainer.angle = -4;
    this.emitterText = new Emitter(this.scoreContainer, Assets.get('textEmitter'));

    this.coinContainer = new Container();
    this.coinContainer.zIndex = 99;
    this.emitterCoin = new Emitter(this.coinContainer, Assets.get('coinEmitter'));
    this.addChild(this.coinContainer);

    this.sortableChildren = true;
    this.sortChildren();
  }

  resize(container: Rect) {
    this.container = container;
    const { width, height } = container;

    const center = { x: width / 2, y: height / 2 };

    this.gradient.width = height;
    this.gradient.height = width;
    this.gradient.position.set(center.x, center.y);

    const treasureWidth = 762;
    const treasureHeight = 481;
    this.treasureContainer.scale.set(Math.min(width, treasureWidth) / treasureWidth);
    this.treasureContainer.position.set(center.x * 1.03, height * 0.95);
    this.emitterTreasuerBackLight.updateOwnerPos(0, -treasureHeight / 2);
    this.emitterTreasuerLight.updateOwnerPos(0, -treasureHeight / 2);

    this.props = { ...this.defaultProps };
    const fontSize = Math.min(this.props.textStyles.fontSize as number, width / 6);
    this.props.textStyles.fontSize = fontSize;

    this.yourScoreText.style = this.props.textStyles.clone();
    this.yourScoreText.style.fontSize = fontSize * 0.8;
    this.yourScoreText.position.y = -this.yourScoreText.style.fontSize;

    this.valueText.style = this.props.textStyles.clone();

    const textBounds = TextMetrics.measureText('0', this.props.textStyles);
    this.graphics.clear();
    this.graphics.lineStyle(fontSize / 8, this.props.borderColor,);
    this.graphics.beginFill(this.props.scoreColor);
    this.graphics.drawRoundedRect(
      -textBounds.width * 6 * 0.55,
      -textBounds.height * 0.55,
      textBounds.width * 6 * 1.1,
      textBounds.height * 1.1, this.props.borderRadius);
    this.graphics.endFill();

    this.scoreContainer.position.set(center.x, height / 4);

    this.emitterTopLight.updateOwnerPos(center.x, 0);
    this.coinContainer.position.set(center.x, 0);
    this.coinContainer.scale.set(this.treasureContainer.scale.x);

    this.draw();
  }

  draw(container: Rect = this.container) {
    // this.resize(container);
  }

  async in(): Promise<void> {
    this.alpha = 0;
    this.treasureSpriteContainer.scale.set(0);
    this.scoreContainer.scale.set(0);
    this.animation?.kill();

    this.animation = gsap.timeline();
    this.animation
      .to(this, { alpha: 1, duration: 0.3 })
      .to(this.scoreContainer.scale, {x: 1, y: 1, duration: 0.3, ease: 'back.out(1.7)'});
    this.animation.to(this, 
      {
        currentValue: this.targetValue, 
        duration: Math.min(0.8, this.targetValue * 0.01), 
        ease: 'none', 
        onStart: () => {
          sound.play('countScoreSound', { volume: 0.5, loop: true });
        },
        onUpdate: () => {
          this.valueText.text = Math.round(this.currentValue).toString();
          this.emitterText.emit = true;
        }, 
        delay: 0.2
      }
    );
    this.animation.to(this.treasureSpriteContainer.scale, 
      {
        x: 1, 
        y: 1, 
        duration: 0.3, 
        ease: 'back.out(1.7)', 
        delay: 0.2,
        onStart: () => {
          sound.stop('countScoreSound');
          Assets.get('showTreasureSound').play();
        },
      }
    );
    await this.animation;

    this.emitterTopLight.emit =
    this.emitterTreasuerLight.emit =
    this.emitterCoin.emit =
    this.emitterTreasuerBackLight.emit = true;
  }

  async out(): Promise<void> {
    this.animation?.kill();
    this.animation = gsap
      .timeline()
      .to(this, { alpha: 0, duration: 0.3 });

    await this.animation;
    this.emitterTopLight.emit =
      this.emitterTreasuerLight.emit =
      this.emitterCoin.emit =
      this.emitterTreasuerBackLight.emit = false;
    this.alpha = 0;
  }
}