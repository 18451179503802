import { wait } from '../async/awaitable';

type progressionCallback = (progress: number) => void;
type task = (progression?: progressionCallback) => Promise<void>;

export interface ITransitioner {
  transition(...tasks: task[]): Promise<void>;
}

export type Transition = {
  in: () => Promise<void>;
  out: () => Promise<void>;
};

export class Transitioner<T extends Transition = Transition> implements ITransitioner {
  constructor(protected view: T, private minDuration: number = 1000) {}
  async transition(
    ...tasks: ((progression?: ((progress: number) => void) | undefined) => Promise<void>)[]
  ): Promise<void> {
    await this.view.in();
    const time = Date.now();
    for (const task of tasks) {
      await task(this.progress.bind(this));
    }
    const remainingTime = this.minDuration - (Date.now() - time);
    if (remainingTime > 0) await wait(remainingTime);
    //TODO: Sepereate view in and out
    // await this.view.out();
  }

  // Once progress is working, uncomment the below
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  progress(progress: number) {}
}
