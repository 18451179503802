// eslint-disable-next-line no-restricted-syntax
import { DisplayObject, Application } from 'pixi.js';
import { Disposable } from '../interfaces/disposable';
import { DisplayListObserver } from '../pixi/display-scaler';
import { Transition, Transitioner } from './transitioner';

export type PixiTransition = DisplayObject & Transition;

export class PixiTransitioner<T extends PixiTransition> extends Transitioner<T> implements Disposable {
  displayListObserver: DisplayListObserver;
  constructor(private application: Application, transition: T) {
    super(transition);
    this.displayListObserver = new DisplayListObserver(
      this.application.stage,
      this.refresh.bind(this),
      this.refresh.bind(this)
    );
  }
  dispose(): void {
    this.displayListObserver.dispose();
  }

  refresh() {
    if (this.view.parent) this.application.stage.setChildIndex(this.view, this.application.stage.children.length - 1);
  }

  async transition(
    ...tasks: ((progression?: ((progress: number) => void) | undefined) => Promise<void>)[]
  ): Promise<void> {
    this.application.stage.addChild(this.view);
    this.view.zIndex = Number.MAX_SAFE_INTEGER;
    this.application.stage.sortChildren();
    this.application.stage.setChildIndex(this.view, this.application.stage.children.length - 1);
    if ('resize' in this.view) (this.view as any).resize(this.application.screen);
    await super.transition(...tasks);
    this.viewOut().catch((error) => console.error(error));
  }

  async viewOut() {
    await this.view.out();
    this.application.stage.removeChild(this.view);
  }
}
